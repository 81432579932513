import React from "react";
import {useState} from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner';


import { Editor,EditorTextChangeEvent  } from 'primereact/editor';
import { InputTextarea } from 'primereact/inputtextarea';
        
                

export const GET_ASSESMENTS = gql`
query count {
  license_key_count
  assesments_count
}
`

interface LargeText {
  id: number
  category: number
  title: string
  description?: string
  placeholder: string
  hasValue?: boolean
}


const ItemRenderer = ({item}) => {
  const [text, setValue] = useState<string>();

  const renderHeader = () => {
    return (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
    );
  };

  const header = renderHeader();

  return(
  <div className="flex align-items-center px-3 py-2 cursor-pointer">
    <div>
    <h3>{item.title }</h3>
    <h4>{item.description }</h4>
    <InputTextarea value={text} placeholder={item.placeholder} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}  rows={10} cols={80} />
    </div>
  </div>
  );
};

const MapIndex = ({items}) => {
  return(
    <div style={{position: "sticky",top:0}}>
      <ul>
      {items.map(i=><li><a href={`#category_${i.category}`}>{i.title}</a></li>)}
      </ul>
    </div>
  );
};

const MapDetails = ({items, details}) => {
  return(
    <div>
      {items.map(i=>
        <div style={{width:900}}>
        <a id={`category_${i.category}`}><h1>{i.title}</h1></a>
        {details.filter(d=>d.category===i.category).map((item:LargeText)=> <ItemRenderer item={item}/>)}      
        </div>      
      
      )}
      

    </div>
  );
};

export const MapaPage = () => {

  const {loading, error, data} = useQuery(GET_ASSESMENTS, {
    variables: {}
  });
 
  const mapa:LargeText[] = [
    {id:1,category:1,title:"Mapa del Éxito",placeholder:"Con su coach in la sesión inicial"},
    {id:2,category:2,title:"Mis Límites y Fundamento",placeholder:"Antes de la Sesión 1"},
    {id:3,category:3,title:"Mis Fortalezas",placeholder:"Antes de la Sesión 1"},
    {id:4,category:4,title:"3 Elementos del Propósito",placeholder:"Con su coach en la sesión 1"},
    {id:5,category:5,title:"Mi Propósito",placeholder:"Con su coach en la sesión 1"},
    {id:6,category:6,title:"Mi Rueda de Vida",placeholder:"Antes de la Sesión 1"},
    {id:7,category:7,title:"Mi Visión",placeholder:"Antes de la Sesión 1"},
    {id:8,category:8,title:"Mi visión de Líder",placeholder:"Antes de la Sesión 1"},
    {id:9,category:9,title:"Mi Punto Óptimo",placeholder:"Con su coach en la sesión 1"},
    {id:10,category:10,title:"Mi misión",placeholder:"Con su coach en la sesión 1"},
    {id:11,category:11,title:"Mis OKR Objetivos",placeholder:"Con su coach en la sesión 2"},
    {id:12,category:12,title:"Mis OkR Key results",placeholder:"Antes de la sesión 3"},
    {id:13,category:13,title:"Mis metas cuantificables",placeholder:"Antes de la sesión 3"}    
  ]
  
  const fundaments:LargeText[] = [
    {
      id: 1,
      category: 1,
      title: "Mis Yo creo (Mi Perspectiva)",
      description:"Así es como usted mira el mundo. Comience cada oración con 'Yo creo'",
      placeholder:"Escribe aqui … Ejemplo: Yo Creo que los buenos líderes ayudan a sus equipos a brillar"
    },
    {
      id: 2,
      category: 1,
      title: "Mis Yo amo (Mi Pasión)",
      description:"Esto es lo que usted ama. Comience cada oración con un 'Yo amo'",
      placeholder:"Escribe aqui … Ejemplo: Yo amo leer. Yo amo el ejercicio."
        
    },
    {
      id: 3,
      category: 1,
      title: "Mis Yo soy",
      description:"Así es como se mira a sí mismo (a). Comience cada oración con un 'Yo soy'",
      placeholder:"Escribe aqui …Ejemplo: Yo soy ordenado. Yo soy analítico."
    },
    {
      id: 4,
      category: 1,
      title: "Mis Yo vivo para",
      description:"Este es su por qué. Comience cada oración  con “Yo vivo para” o 'Yo ayudo'",
      placeholder:"Escribe aqui … Ejemplo: Yo vivo para ayudar a empresarios a organizar sus empresas y crecer."
    },
    {
      id: 5,
      category: 1,
      title: "Mis Yo Valoro (Valores)",
      description:"Esto es lo que posee valor en su vida. Comience cada oración con 'Yo valoro'",
      placeholder:"Escribe aqui … Ejemplo: Yo valoro la honestidad. Yo valoro la familia."
    },
    {
      id: 6,
      category: 1,
      title: "Mis Prioridades",
      description:"El orden que le pongo a las diferentes partes de mi vida. Comience con una oración 'para mi es importante'",
      placeholder:"Escribe aqui …Ejemplo: para mi es importante dedicar tiempo a la familia los fines de semana. Para mi es importante relacionarme con personas que me eleban"
    },
    {
      id: 7,
      category: 2,
      title: "Mis fortalezas",      
      placeholder:"Enumera  aqui  tus fortalezas..."
    },
    {
      id: 8,
      category: 2,
      title: "Estrategias para potenciar sus fortalezas",
      placeholder:"Trabajar esta sección con su coach..."
    },
    {
      id: 9,
      category: 2,
      title: "Mis Debilidades",
      placeholder:"Enumera  aqui  tus debilidades..."
    },
    {
      id: 10,
      category: 2,
      title: "Estrategias para administrar mis debilidades",
      placeholder:"Trabajar esta sección con su coach..."
    },
    {
      id: 11,
      category: 2,
      title: "Mi Zona Gris",
      placeholder:"Escribe aquí..."
    },
    {
      id: 12,
      category: 2,
      title: "Estrategias para salir de su zona gris",
      placeholder:"Trabajar esta sección con su coach..."
    },
    {
      id: 13,
      category: 3,
      title: "Audiencia o Tribu (A quien ayudo)",
      placeholder:"Personas o grupos de personas con las que te gustaría invertir el tiempo. Escribe las diferentes personas con las que te gusta trabajar, por ejemplo líderes, financistas, empresarios..."
    },
    {
      id: 14,
      category: 3,
      title: "Aporte o Causa (En qué les ayudo)",
      placeholder:"Tres o cuatro actividades en las que te gustaría enfocarte al ayudar a estas personas. Por ejemplo, liderar, guiar, entrenar."
    },
    {
      id: 15,
      category: 3,
      title: "Aplicación o acción (Cómo les ayudo)",
      placeholder:"¿Cómo ayudarás a las personas? Usa 4 o 5 palabras de acción (verbos) para describir cómo ayudarás. Por ejemplo: Destacar profesionalmente, entrenar físicamente, crecer en sus empresas."
    },
    {
      id: 16,
      category: 4,
      title: "Audiencia o Tribu (A quien ayudo)",
      placeholder:"Personas o grupos de personas con las que te gustaría invertir el tiempo. Escribe las diferentes personas con las que te gusta trabajar, por ejemplo líderes, financistas, empresarios..."
    },
    {
      id: 17,
      category: 4,
      title: "Aporte o Causa (En qué les ayudo)",
      placeholder:"Tres o cuatro actividades en las que te gustaría enfocarte al ayudar a estas personas. Por ejemplo, liderar, guiar, entrenar."
    },
    {
      id: 18,
      category: 4,
      title: "Aplicación o acción (Cómo les ayudo)",
      placeholder:"¿Cómo ayudarás a las personas? Usa 4 o 5 palabras de acción (verbos) para describir cómo ayudarás. Por ejemplo: Destacar profesionalmente, entrenar físicamente, crecer en sus empresas."
    },
    {
      id: 19,
      category: 5,
      title: "Visión y enfoque estratégico",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 20,
      category: 5,
      title: "Manejo del Cambio",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },    
    {
      id: 21,
      category: 5,
      title: "Comunicación",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 22,
      category: 5,
      title: "Resolución de problemas",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 23,
      category: 5,
      title: "Desarrollo del Equipo",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 24,
      category: 5,
      title: "Manejo de Conflicto",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 25,
      category: 5,
      title: "Delegación",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 26,
      category: 5,
      title: "Influencia",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 27,
      category: 6,
      title: "Carrera (Estudio o Trabajo)",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 28,
      category: 6,
      title: "Recreación",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 28,
      category: 6,
      title: "Finanzas",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 29,
      category: 6,
      title: "Desarrollo Personal",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 30,
      category: 6,
      title: "Salud",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 31,
      category: 6,
      title: "Relaciones",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 32,
      category: 6,
      title: "Familia",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },
    {
      id: 33,
      category: 6,
      title: "Servicio",
      placeholder:"Describe acá cómo se vería esta área al ser un 10"
    },

  ];

  
  return (
    <div className="card flex  justify-content-center gap-5"  >
      <MapIndex items={mapa}/>
      <MapDetails items={mapa} details={fundaments}/>
    </div>
  );
};

export default withAuthenticationRequired(MapaPage, {
  onRedirecting: () => <SpinnerContainer />,
});