import React from 'react'; 

import { useAuth0 } from "@auth0/auth0-react";

import { Menubar } from 'primereact/menubar';
import { Avatar } from 'primereact/avatar'; 

export default function BasicDemo() {
    
    const {
        user,
        isAuthenticated,
        logout,
      } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
              returnTo: window.location.origin,
            }
        });
//console.info("ℹ️ User",user)
    const end = (
        <Avatar image={user?.picture} shape="circle" />
    );
     
    //let LogInItem = {};
    let ProfileItem = {};
    if(isAuthenticated) {
        ProfileItem = {
            icon: 'pi pi-bars',
            items: [
                {
                    label: 'Home',
                    url: '/',
                    icon: 'pi pi-home',
                },
                {
                    label: 'Evaluaciones',
                    url: '/license',
                    icon: 'pi pi-key'
                },
                {
                    label: 'Mis Evaluados',
                    url: '/assesments',
                    icon: 'pi pi-compass'
                },
                {
                    label: 'Profile',
                    icon: 'pi pi-user',
                    url:'/profile'
                },
                {
                    label: 'Log out',
                    icon: 'pi pi-sign-out',
                    command: () => logoutWithRedirect()
                },
            ]
        }

        if(user.rolesId.includes("Supervisor")) {
            ProfileItem.items.splice(3,0,
                {
                    label: 'Users',
                    url: '/users',
                    icon: 'pi pi-user'
                }
            )            

        }

    } /*else {
        LogInItem = {
            label: 'Log in',
            icon: 'pi pi-sign-in',
            command: () => loginWithRedirect()
        };
    }*/


    const items = [
        ProfileItem
    ];

    return (
        <div className="card">
            <Menubar model={items} end={isAuthenticated &&end} />
        </div>
    )
}