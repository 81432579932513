//import logo from './logo.svg';
import './App.css';


import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import * as pages from "./pages/index.ts";
/* en lugar de importarlos uno a uno se importa todo
import { Pillars } from './pages/pillar.tsx';
*/
import Profile from "./pages/Profile.tsx";
import AssesmentsPage from "./pages/assesments.tsx";
import CoacheesPage from "./pages/coahees.tsx";
import LicenseKeysPage from "./pages/licenseKeysPage.tsx";
import LicenseKeyPage from "./pages/licenseKeyPage.tsx";
import LicenseKeyReportPage from "./pages/licenseKeyReportPage.tsx"
import RedeemPage from "./pages/redeem.tsx";
import HomePage from "./pages/home.tsx";
import MapaPage from "./pages/mapa.tsx";

import BasicDemo from "./components/NavBar.tsx"

function App() {
  const { user } = useAuth0();
  const userIs = {
    supervisor : user ? user.rolesId.includes("Supervisor") : false
  , coach      : user ? user.rolesId.includes("Coach") : false
  }
    
  return (
      <BrowserRouter>
      <BasicDemo/>
       <Routes>
       
       { userIs.coach 
         ? <Route exact path="/" element={<HomePage />} />
         : <Route exact path="/" element={<LicenseKeysPage />} />
       }

       <Route exact path="/profile" element={<Profile />} />
       <Route exact path="/pillars" element={<pages.Pillars />} />
       <Route exact path="/pillar/:pillarId" element={<pages.Pillar />} />
       <Route exact path="/assesments" element={<AssesmentsPage />} />
       <Route exact path="/assesment/:assesmentId" element={<pages.Assesment />} />
       <Route exact path="/users" element={<CoacheesPage />} />
       <Route exact path="/license" element={<LicenseKeysPage />} />
       <Route exact path="/license/:licenseKeyId" element={<LicenseKeyPage />} />
       <Route exact path="/license/:licenseKeyId/informe" element={<LicenseKeyReportPage />} />
       <Route exact path="/redeem/:licenseKey" element={<RedeemPage />} />
       <Route exact path="/mapa/" element={<MapaPage />} />
       </Routes>
      </BrowserRouter>
  );
}

export default App;

/*
<Route exact path="/license/:licenseKey" element={<LicenseKeyPage />} />
**/